<template>
  <b-modal
    id="update-qualification-evidence-modal"
    title="Update Qualification Evidence"
    lazy
    size="lg"
    no-close-on-backdrop
    @show="onShow"
    @hidden="onClose"
  >
    <div v-if="evidenceObj">
      <b-form-group label="Title" label-for="evidence-title">
        <b-form-input id="evidence-title" v-model="title" :disabled="loading" />
      </b-form-group>

      <b-form-group label="Version" label-for="evidence-version">
        <b-form-input id="evidence-version" v-model="version" :disabled="loading" />
      </b-form-group>

      <b-form-group label="Document" label-for="evidence-document">
        <b-form-input id="evidence-document" v-model="documentId" :disabled="loading" />
      </b-form-group>

      <b-form-group label="Document Link" label-for="evidence-link">
        <b-form-input id="evidence-link" v-model="documentLink" :disabled="loading" />
      </b-form-group>

      <b-form-group label="Document Date" label-for="evidence-date">
        <b-form-datepicker
          id="evidence-date"
          v-model="documentDate"
          v-b-popover.hover.top="`Click Calendar Icon to Select Date`"
          :disabled="loading"
        />
      </b-form-group>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="outline-secondary" @click="onClose">
        Discard
      </b-button>

      <b-button variant="success" :disabled="loading" @click="onSubmit">
        <span v-if="loading">
          <b-spinner small type="grow" />
          Creating...
        </span>
        <span v-else>Update Qualification Evidence</span>
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import store from '@/store'
import {
  computed,
  ref,
} from '@vue/composition-api'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'UpdateQualificationEvidenceModal',
  components: { BButton },
  setup(props, context) {
    const loading = ref(false)
    const evidenceObj = computed(() => store.state.qualificationEvidence.selectedEvidence)
    const title = ref(null)
    const version = ref(null)
    const documentId = ref(null)
    const documentLink = ref(null)
    const documentDate = ref(null)

    const onShow = () => {
      title.value = evidenceObj.value.title
      version.value = evidenceObj.value.version
      documentId.value = evidenceObj.value.document_id
      documentLink.value = evidenceObj.value.document_link
      documentDate.value = evidenceObj.value.document_date
    }
    const onClose = () => {
      loading.value = false
      title.value = null
      version.value = null
      documentId.value = null
      documentLink.value = null
      documentDate.value = null
      context.root.$bvModal.hide('update-qualification-evidence-modal')
    }
    const onSubmit = () => {
      const payload = {
        title: title.value,
        version: version.value,
        document_id: documentId.value,
        document_link: documentLink.value,
        document_date: documentDate.value,
      }

      loading.value = true
      store.dispatch('qualificationEvidence/updateEvidence', payload)
        .then(data => {
          context.emit('on-update', data)
        })
        .finally(() => {
          loading.value = false
          onClose()
        })
    }

    return {
      loading,
      evidenceObj,
      title,
      version,
      documentId,
      documentLink,
      documentDate,

      onShow,
      onClose,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
