<template>
  <b-modal
    id="create-qualification-evidence-modal"
    title="Create Qualification Evidence"
    lazy
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
  >
    <b-form-group label="Title" label-for="evidence-title">
      <b-form-input id="evidence-title" v-model="title" :disabled="loading" />
    </b-form-group>

    <b-form-group label="Version" label-for="evidence-version">
      <b-form-input id="evidence-version" v-model="version" :disabled="loading" />
    </b-form-group>

    <b-form-group label="Document" label-for="evidence-document">
      <b-form-input id="evidence-document" v-model="documentId" :disabled="loading" />
    </b-form-group>

    <b-form-group label="Document Link" label-for="evidence-link">
      <b-form-input id="evidence-link" v-model="documentLink" :disabled="loading" />
    </b-form-group>

    <b-form-group label="Document Date" label-for="evidence-date">
      <b-form-datepicker
        id="evidence-date"
        v-model="documentDate"
        :disabled="loading"
        v-b-popover.hover.top="`Click Calendar Icon to Select Date`"
      />
    </b-form-group>

    <template v-slot:modal-footer>
      <b-button variant="outline-secondary" @click="onClose">
        Discard
      </b-button>

      <b-button variant="success" :disabled="loading" @click="onSubmit">
        <span v-if="loading">
          <b-spinner small type="grow" />
          Creating...
        </span>
        <span v-else>Create Qualification Evidence</span>
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import store from '@/store'
import { ref } from '@vue/composition-api'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'CreateQualificationEvidenceModal',
  components: { BButton },
  setup(props, context) {
    const loading = ref(false)
    const title = ref(null)
    const version = ref(null)
    const documentId = ref(null)
    const documentLink = ref(null)
    const documentDate = ref(null)

    const onClose = () => {
      loading.value = false
      title.value = null
      version.value = null
      documentId.value = null
      documentLink.value = null
      documentDate.value = null
      context.root.$bvModal.hide('create-qualification-evidence-modal')
    }
    const onSubmit = () => {
      const data = {
        title: title.value,
        version: version.value,
        document_id: documentId.value,
        document_link: documentLink.value,
        document_date: documentDate.value,
      }

      loading.value = true
      store.dispatch('qualificationEvidence/createEvidence', data)
        .then(data => { context.emit('on-create', data) })
        .finally(() => {
          loading.value = false
          onClose()
        })
    }

    return {
      loading,
      title,
      version,
      documentId,
      documentLink,
      documentDate,

      onClose,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
