<template>
  <div id="QualificationEvidenceView">
    <div class="w-100 d-inline-flex justify-content-between align-items-center mb-1">
      <h2>Qualification Evidence</h2>
      <b-button-group>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          size="sm"
          @click="openCreateModal"
        >
          <feather-icon icon="PlusIcon" />
          Create Qualification Evidence
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-info"
          size="sm"
          @click="fetchEvidence"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
      </b-button-group>
    </div>

    <div v-if="loading">
      <div class="w-100 d-inline-flex justify-content-center mt-4">
        <div class="d-flex flex-column align-items-center">
          <h5 class="text-warning mb-2">
            <atom-spinner class="animate-pulse" />
          </h5>
          <h5>
            Loading Qualification Evidence...
          </h5>
        </div>
      </div>
    </div>
    <div v-else class="w-100 d-flex justify-content-around">
      <qualification-evidence-table-view
        class="w-100"
        :qualification-evidence="evidenceList"
        @open-update-modal="openUpdateModal"
        @open-delete-modal="openDeleteModal"
      />
      <!-- Column: Actions -->
      <!--<template #cell(actions)="data">-->
      <!--  <b-dropdown variant="link" no-caret>-->
      <!--    <template #button-content>-->
      <!--      <feather-icon-->
      <!--        icon="MoreVerticalIcon"-->
      <!--        size="16"-->
      <!--        class="align-middle text-body"-->
      <!--      />-->
      <!--    </template>-->
      <!--    <b-dropdown-item @click="openHistory(data.item)">-->
      <!--      <font-awesome-icon :icon="['fas', 'history']" />-->
      <!--      <span class="align-middle ml-50">History</span>-->
      <!--    </b-dropdown-item>-->
      <!--  </b-dropdown>-->
      <!--</template>-->
    </div>

    <div id="QEV_Modals">
      <create-qualification-evidence-modal @on-create="fetchEvidence" />
      <update-qualification-evidence-modal @on-update="fetchEvidence" />
      <delete-qualification-evidence-modal @on-delete="fetchEvidence" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import QualificationEvidenceTableView from '@/views/OQE/QualificationEvidenceTableView.vue'
import CreateQualificationEvidenceModal from '@/components/ObjectiveQualificationEvidence/Evidence/CreateQualificationEvidenceModal.vue'
import UpdateQualificationEvidenceModal from '@/components/ObjectiveQualificationEvidence/Evidence/UpdateQualificationEvidenceModal.vue'
import DeleteQualificationEvidenceModal from '@/components/ObjectiveQualificationEvidence/Evidence/DeleteQualificationEvidenceModal.vue'

export default {
  name: 'QualificationEvidence',
  directives: { Ripple },
  components: {
    AtomSpinner,
    QualificationEvidenceTableView,
    CreateQualificationEvidenceModal,
    UpdateQualificationEvidenceModal,
    DeleteQualificationEvidenceModal,
  },
  setup(props, context) {
    const loading = ref(false)
    const evidenceList = computed(() => store.state.qualificationEvidence.evidenceList)

    const fetchEvidence = () => {
      loading.value = true
      store
        .dispatch('qualificationEvidence/fetchEvidenceList')
        .finally(() => { loading.value = false })
    }
    fetchEvidence()

    const __openModal = (obj, modalId) => {
      store.dispatch('qualificationEvidence/getEvidence', obj.id)
        .then(() => { context.root.$bvModal.show(modalId) })
    }
    const openCreateModal = () => { context.root.$bvModal.show('create-qualification-evidence-modal') }
    const openUpdateModal = obj => { __openModal(obj, 'update-qualification-evidence-modal') }
    const openDeleteModal = obj => { __openModal(obj, 'delete-qualification-evidence-modal') }

    // Sidebar
    const isSidebarVisible = ref(false)
    const sidebarData = ref({})
    function openSidebar(rowData) {
      store.dispatch('qualificationEvidence/getEvidence', rowData)
        .then(() => {
          console.log({ rowData })
          isSidebarVisible.value = true
          sidebarData.value = rowData
        })
    }
    function hideSidebar() {
      isSidebarVisible.value = false
    }
    function openHistory(rowData) {
      this.$router.push(
        {
          name: 'node_history',
          params: {
            modelId: sessionStorage.getItem('kompozition-workspace'),
            nodeId: rowData.id,
          },
        },
      )
    }

    return {
      loading,
      evidenceList,
      fetchEvidence,

      openCreateModal,
      openUpdateModal,
      openDeleteModal,

      // Sidebar
      isSidebarVisible,
      sidebarData,
      openSidebar,
      hideSidebar,

      // History
      openHistory,
    }
  },
}
</script>
