<template>
  <b-modal
    id="delete-qualification-evidence-modal"
    title="Delete Qualification Evidence. Are you sure?"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Yes, delete evidence'"
    :ok-disabled="loading"
    ok-variant="danger"
    cancel-title="Keep"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @ok.prevent="onSubmit"
  >
    <b-alert v-if="evidenceObj" show variant="danger">
      <div class="alert-body d-inline-flex justify-content-between">
        <div class="mr-1">
          <feather-icon
            icon="AlertCircleIcon"
            class="text-white"
            size="30"
          />
        </div>
        <div>
          <p>
            You're about to permanently delete the qualification evidence
            <span class="font-weight-bolder">{{ evidenceObj.title }}</span>
            and
            <span class="font-weight-bolder">and any links it may contain.</span>.
          </p>
          <p>
            To avoid unintended data loss, consider reviewing this action before deleting it.
          </p>
        </div>
      </div>
    </b-alert>
    <div>
      <b-table
        responsive="sm"
        :fields="tableFields"
        :items="tableData"
        hover
      />
    </div>
  </b-modal>
</template>

<script>
import store from '@/store'
import { computed, ref, watch } from '@vue/composition-api'
import { BAlert, BTable } from 'bootstrap-vue'

export default {
  name: 'DeleteQualificationEvidenceModal',
  components: { BAlert },
  setup(props, context) {
    const loading = ref(false)
    const evidenceObj = computed(() => store.state.qualificationEvidence.selectedEvidence)
    const tableFields = [
      {
        key: 'property', label: 'Property', thStyle: 'width: 10rem;', tdClass: 'font-small-3 text-muted',
      },
      { key: 'value', label: 'Value', tdClass: 'font-small-3' },
    ]

    const tableData = ref([])
    watch(evidenceObj, val => {
      if (evidenceObj.value) {
        tableData.value = [
          { property: 'ID', value: evidenceObj.value.id },
          { property: 'Title', value: evidenceObj.value.title },
          { property: 'Version', value: evidenceObj.value.version },
          { property: 'Document ID', value: evidenceObj.value.document_id },
          { property: 'Document Link', value: evidenceObj.value.document_link },
          { property: 'Document Date', value: evidenceObj.value.document_date },
          { property: 'Created By', value: `${evidenceObj.value.created_by} at ${evidenceObj.value.created}` },
          {
            property: 'Updated By',
            value: evidenceObj.value.updated_by ? `${evidenceObj.value.updated_by} at ${evidenceObj.value.updated}` : 'Never',
          },
        ]
      }
    })

    const onSubmit = () => {
      loading.value = true
      store
        .dispatch('qualificationEvidence/deleteEvidence')
        .then(() => {
          context.emit('on-delete')
          context.root.$bvModal.hide('delete-qualification-evidence-modal')
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      evidenceObj,
      tableFields,
      tableData,
      onSubmit,
    }
  },
}
</script>
